import {
  UserPermissions,
  UserMemberCustomerType,
  UserProfile,
  UserRoles,
} from '@/api/user/interface';
import { UserInsightPreference } from '@/api/insights/interface';

export interface UserState {
  categories: string[];
  customers: UserMemberCustomerType[];
  insightPreferences: UserInsightPreference[];
  profiles: UserProfile[];
  role: UserRoles;
  selectedProfile?: UserProfile;
  username: string;
}

export enum UserActionTypes {
  ADD_USER_INSIGHT_PREFERENCE = '@@distilr/user/ADD_USER_INSIGHT_PREFERENCE',
  SET_SELECTED_USER_PROFILE = '@@distilr/user/SET_SELECTED_USER_PROFILE',
  SET_USERNAME = '@@distilr/user/SET_USERNAME',
  SET_USER_INSIGHT_PREFERENCES = '@@distilr/user/SET_USER_INSIGHT_PREFERENCES',
  SET_USER_PERMISSIONS = '@@distilr/user/SET_USER_PERMISSIONS',
  SET_USER_PROFILES = '@@distilr/user/SET_USER_PROFILES',
  SET_USER_ROLE = '@@distilr/user/SET_USER_ROLE',
  UPDATE_SELECTED_USER_PROFILE = '@@distilr/user/UPDATE_SELECTED_USER_PROFILE',
}

export interface SetUserProfiles {
  type: UserActionTypes.SET_USER_PROFILES;
  payload: UserProfile[];
}

export interface SetUserRole {
  type: UserActionTypes.SET_USER_ROLE;
  payload: UserRoles;
}

export interface SetSelectedUserProfile {
  type: UserActionTypes.SET_SELECTED_USER_PROFILE;
  payload: UserProfile;
}

export interface SetUserPermissionsType {
  type: UserActionTypes.SET_USER_PERMISSIONS;
  payload: UserPermissions;
}

export interface SetUsernameType {
  type: UserActionTypes.SET_USERNAME;
  payload: string;
}

export interface SetUserInsightPreferencesType {
  type: UserActionTypes.SET_USER_INSIGHT_PREFERENCES;
  payload: UserInsightPreference[];
}

export interface AddUserInsightPreferenceType {
  type: UserActionTypes.ADD_USER_INSIGHT_PREFERENCE;
  payload: UserInsightPreference;
}

export interface UpdateSelectedUserProfile {
  type: UserActionTypes.UPDATE_SELECTED_USER_PROFILE;
  payload: UserProfile;
}

export type UserActions =
  | SetSelectedUserProfile
  | SetUserRole
  | SetUserProfiles
  | SetUserPermissionsType
  | SetUsernameType
  | SetUserInsightPreferencesType
  | AddUserInsightPreferenceType
  | UpdateSelectedUserProfile;
