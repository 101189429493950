import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

// don't run Sentry on local - change it if you want to see local errors in Sentry
// if (window.location.hostname !== 'localhost') {
const sentry_errors: Record<string, any> = {};
Sentry.init({
  dsn: 'https://76e9ce994bef492d89c8a0f635cf8fbd@o1169560.ingest.sentry.io/6443100',
  sampleRate: 1, // which amount of events to send 1 = 100%
  enabled: true, // set to false to disable sentry sending events
  environment: process.env.REACT_APP_STAGE,
  release: process.env.REACT_APP_GIT_SHA,
  attachStacktrace: true,
  integrations: [new BrowserTracing(), new CaptureConsole()],
  beforeSend(event) {
    const eventName = `${event.level}${event?.request?.url}${event?.exception?.values?.[0]?.value}`;
    // send each error once in 8 hours
    const shouldSendEvent =
      !sentry_errors[eventName] ||
      new Date(sentry_errors[eventName] + 1000 * 60 * 60 * 8) < new Date();
    if (shouldSendEvent) {
      sentry_errors[eventName] = Date.now(); // last time occurred
      return event;
    }
    return null;
  },
});
// }
