import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || '';

export function init() {
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const isTest = !process.env.NODE_ENV || process.env.NODE_ENV === 'test';
  ReactGA.initialize(TRACKING_ID, { testMode: isTest });
}

export function sendEvent(payload: { category: string; action: string; label?: string }) {
  ReactGA.event(payload);
}

export function sendPageView(path: string) {
  ReactGA.set({ page: path });
  ReactGA.send({ hitType: 'pageview', page: path, title: 'Custom Title' });
}

const exports = {
  init,
  sendEvent,
  sendPageView,
};

export default exports;
