import { latestUpdatedDateSelector } from '@/store/filter/selectors';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const MONTH_WINDOW: TimeWindow = {};
export const TIME_WINDOW1: TimeWindow = {
  CM: { name: 'Current Month', value: '' },
};

const UpdateConstantsBasedOnDate = () => {
  const maxDate = useSelector(latestUpdatedDateSelector);

  useEffect(() => {
    if (!maxDate) {
      return;
    }

    const [year, month] = maxDate.split('-').map(Number);

    // Calculate the last day of February based on whether it's a leap year
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    const februaryDays = isLeapYear ? 29 : 28;

    // Dynamically generate the values for MONTH_WINDOW
    const updatedMonthWindow: TimeWindow = {
      M1: { name: 'January', value: `${year}-01-01 and ${year}-01-31` },
      M2: { name: 'February', value: `${year}-02-01 and ${year}-02-${februaryDays}` },
      M3: { name: 'March', value: `${year}-03-01 and ${year}-03-31` },
      M4: { name: 'April', value: `${year}-04-01 and ${year}-04-30` },
      M5: { name: 'May', value: `${year}-05-01 and ${year}-05-31` },
      M6: { name: 'June', value: `${year}-06-01 and ${year}-06-30` },
      M7: { name: 'July', value: `${year}-07-01 and ${year}-07-31` },
      M8: { name: 'August', value: `${year}-08-01 and ${year}-08-31` },
      M9: { name: 'September', value: `${year}-09-01 and ${year}-09-30` },
      M10: { name: 'October', value: `${year}-10-01 and ${year}-10-31` },
      M11: { name: 'November', value: `${year}-11-01 and ${year}-11-30` },
      M12: { name: 'December', value: `${year}-12-01 and ${year}-12-31` },
    };

    // Update value of the corresponding month based on maxDate
    const maxDateMonth = new Date(maxDate).getMonth() + 1; // Adding 1 to convert from 0-indexed to 1-indexed month
    const maxDateKey = `M${maxDateMonth}`;
    if (updatedMonthWindow[maxDateKey]) {
      // Update the value based on maxDate
      updatedMonthWindow[maxDateKey].value = `${year}-${
        maxDateMonth < 10 ? '0' + maxDateMonth : maxDateMonth
      }-01 and ${maxDate}`;
    }
    // Dynamically generate the values for TIME_WINDOW1
    const updatedTimeWindow1: TimeWindow = {
      CM: {
        name: 'Current Month',
        value: `${year}-${month < 10 ? '0' + month : month}-01 and ${maxDate}`,
      },
      CYTD: { name: 'Calendar YTD', value: `${year}-01-01 and ${maxDate}` },
      CFYTD: {
        name: 'Foodbuy Fiscal YTD',
        value: `${year - (month < 10 ? 1 : 0)}-10-01 and ${maxDate}`,
      },
    };

    // Reassign the updated constants to the original const variables
    Object.assign(MONTH_WINDOW, updatedMonthWindow);
    Object.assign(TIME_WINDOW1, updatedTimeWindow1);
  }, [maxDate]);

  return null;
};

export default UpdateConstantsBasedOnDate;

// URLs
export enum URLS {
  LANDING = '/',
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
}

export interface TimeWindow {
  [key: string]: {
    name: string;
    value: string;
  };
}

export const MONTH_REFERENCE_POINT = {
  display_name: 'Compared to last month',
  reference_point_id: 'LP',
  reference_point: 'last_month',
  reference_point_type: 'self_time_series',
};

export const CUSTOM_DATE_RANGE_REFERENCE_POINT = {
  display_name: 'Compared to last custom date range',
  reference_point_id: 'CDR',
  reference_point: 'last_month',
  reference_point_type: 'self_time_series',
};

export const QUARTER_REFERENCE_POINT = {
  display_name: 'Compared to last quarter',
  reference_point_id: 'LQ',
  reference_point: 'last_quarter',
  reference_point_type: 'self_time_series',
};

export const YEAR_REFERENCE_POINT = {
  display_name: 'Compared to last year',
  reference_point_id: 'LY',
  reference_point: 'last_year',
  reference_point_type: 'self_time_series',
};

export interface GaEvents {
  [key: string]: {
    category: string;
    action: string;
    label?: string;
  };
}
export enum GaEventCategory {
  INSIGHT = 'Insight',
  FILTER = 'Filter',
  DRILLDOWN = 'Drilldown',
  METRIC = 'Metric',
  USER = 'User',
}
export const GA_EVENTS: GaEvents = {
  INSIGHT_SHOWMORE: { category: GaEventCategory.INSIGHT, action: 'Show More' },
  FILTER_CREATE: { category: GaEventCategory.FILTER, action: 'Create' },
  FILTER_SET: { category: GaEventCategory.FILTER, action: 'Set' },
  FILTER_UPDATE: { category: GaEventCategory.FILTER, action: 'Update' },
  DRILLDOWN_CLICK: { category: GaEventCategory.DRILLDOWN, action: 'Click' },
  DRILLDOWN_EXPORT: { category: GaEventCategory.DRILLDOWN, action: 'Export Data' },
  METRIC_SET_REFERENCE: { category: GaEventCategory.METRIC, action: 'Set Reference' },
  METRIC_SELECT: { category: GaEventCategory.METRIC, action: 'Select' },
  USER_SET_SELECTED_PROFILE: { category: GaEventCategory.USER, action: 'Set Selected Profile' },
};

export const TMP_FILTER_ID = 'unsaved-filter';
