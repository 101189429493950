import { FilterActionTypes, FilterActions, TimeWindowState } from '@/store/filter/types';
import { UserFilterType } from '@/api/filter/interface';
import { UserSession } from '@/api/user/interface';

export function setLatestUpdatedDate(latestUpdatedDate: string): FilterActions {
  return {
    type: FilterActionTypes.SET_LATEST_UPDATED_DATE,
    payload: latestUpdatedDate,
  };
}

export function setTimeWindow(window: TimeWindowState): FilterActions {
  return {
    type: FilterActionTypes.SET_TIME_WINDOW,
    payload: window,
  };
}

export function addUserFilter(filter: UserFilterType): FilterActions {
  return {
    type: FilterActionTypes.ADD_FILTER,
    payload: filter,
  };
}

export function setUserActiveFilter(filter: UserFilterType | null): FilterActions {
  return {
    type: FilterActionTypes.SET_ACTIVE_FILTER,
    payload: filter,
  };
}

export function setUserSavedFilters(filters: UserFilterType[]): FilterActions {
  return {
    type: FilterActionTypes.SET_FILTERS,
    payload: filters,
  };
}

export function updateUserFilter(filter: UserFilterType): FilterActions {
  return {
    type: FilterActionTypes.UPDATE_FILTER,
    payload: filter,
  };
}

export function deleteUserFilter(filter: UserFilterType): FilterActions {
  return {
    type: FilterActionTypes.REMOVE_FILTER,
    payload: filter,
  };
}

export function setPreviousSession(session: UserSession): FilterActions {
  return {
    type: FilterActionTypes.SET_PREVIOUS_SESSION,
    payload: session,
  };
}
