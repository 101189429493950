import { all, call, fork, put, spawn, takeLatest } from 'redux-saga/effects';
// import { registerUserForInsights } from '@/api/insights';
import {
  getSessionFromAuthCode,
  getUserMemberPermissions,
  getUserSession,
  getUserInfo,
  getMetricList,
} from '@/api/user';
import { AuthDetails, UserRoles } from '@/api/user/interface';
import {
  setUserSavedFilters,
  setPreviousSession,
  setLatestUpdatedDate,
} from '@/store/filter/actions';
import { setMetricList } from '@/store/metric/actions';
import { SessionActionTypes, ValidateAuthCodeAction } from '@/store/session/types';
import {
  setSessionError,
  setMustSelectProfile,
  setFetchUserData,
  setSessionInitialized,
} from '@/store/session/actions';
import { UserActionTypes } from '@/store/user/types';
import {
  setUserProfiles,
  setUserRole,
  setSelectedUserProfile,
  setUserPermissions,
  setUserName,
} from '@/store/user/actions';
import { getLatestUpdatedDate } from '@/api/data';

function* onValidateAttempt(action: ValidateAuthCodeAction) {
  try {
    const { role, profiles }: AuthDetails = yield call(getSessionFromAuthCode, action.payload);
    yield put(setUserRole(role));
    yield put(setUserProfiles(profiles));

    // AcctMgr roles must choose their profile if they have more than one (0 is okay since we have a different screen for this)
    if (role === UserRoles.AcctMgr && profiles.length !== 1) {
      yield put(setMustSelectProfile());
      return;
    }

    // Should always be one, AcctMgr role has their own messaging for this which is also handled in a component
    if (role !== UserRoles.AcctMgr && !profiles.length) {
      throw new Error(
        'No Members found on this account. Contact a Foodbuy Administrator for more details.',
      );
    }

    // Everyone else just choose their profile for them (should always have one)
    yield put(setSelectedUserProfile(profiles[0]));
    yield put(setFetchUserData());
  } catch (e: any) {
    console.warn(`Error in onValidateAttempt: ${e}`);
    yield put(setSessionError(e.message));
  }
}

function* onFetchUserData(): any {
  // yield spawn(registerUserForInsights); //Removing Insights API call

  try {
    // Get all initial data needed here
    const [userPermissions, userInfo, previousSession, metricList, latestUpdateDate] = yield all([
      call(getUserMemberPermissions),
      call(getUserInfo),
      call(getUserSession),
      call(getMetricList),
      call(getLatestUpdatedDate),
    ]);

    const modifiedMetricList = metricList.map((metric: any) => {
      // Remove reference points with specific reference_point_id values
      const updatedReferencePoints = (metric.reference_points as any[]).filter(
        (point) => point.reference_point_id !== 'LY' && point.reference_point_id !== 'LW',
      );

      // Return the modified metric object
      return {
        ...metric,
        reference_points: updatedReferencePoints,
      };
    });

    yield put(setUserPermissions(userPermissions));
    yield put(setUserSavedFilters(userInfo.filters || []));
    yield put(setUserName(userInfo.user_name));
    yield put(setPreviousSession(previousSession));
    yield put(setMetricList(modifiedMetricList));
    yield put(setLatestUpdatedDate(latestUpdateDate));
    yield put(setSessionInitialized());
  } catch (e: any) {
    console.warn(`Error in onUserFetchData: ${e}`);
    yield put(setSessionError('User Initialization Fail. Please Try Again.'));
  }
}

function* onUserProfileChange(): any {
  try {
    const [userPermissions, userInfo] = yield all([
      call(getUserMemberPermissions),
      call(getUserInfo),
    ]);
    yield put(setUserPermissions(userPermissions));
    yield put(setUserSavedFilters(userInfo.filters || []));
  } catch (e: any) {
    console.warn(`Error in onUserProfileChange: ${e}`);
  }
}

function* validateAttemptWatcher() {
  yield takeLatest(SessionActionTypes.VALIDATE_AUTH_CODE, onValidateAttempt);
}

function* fetchDataWatcher() {
  yield takeLatest(SessionActionTypes.FETCH_USER_DATA, onFetchUserData);
}

function* updateUserProfileWatcher() {
  yield takeLatest(UserActionTypes.UPDATE_SELECTED_USER_PROFILE, onUserProfileChange);
}

// Root Saga
export default function* rootSaga() {
  yield all([fork(validateAttemptWatcher), fork(fetchDataWatcher), fork(updateUserProfileWatcher)]);
}
