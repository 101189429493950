import { all, fork, select, takeLatest } from 'redux-saga/effects';

import { saveUserSession } from '@/api/user';
import { FilterActionTypes, TimeWindowState } from '@/store/filter/types';
import { MetricActionTypes } from '@/store/metric/types';
import { metricSelectedSelector } from '@/store/metric/selectors';
import { filterTimeWindowSelector } from '@/store/filter/selectors';

function* saveDashboardSession(): any {
  try {
    const timeWindow: TimeWindowState = yield select(filterTimeWindowSelector);
    const selectedMetrics = yield select(metricSelectedSelector);
    saveUserSession({
      window_size: timeWindow.preset,
      filter_name: '',
      metrics: selectedMetrics,
    });
  } catch (e: any) {
    console.warn(`Error in onUserFetchData: ${e}`);
  }
}

function* userDashboardWatcher() {
  yield takeLatest(
    [
      FilterActionTypes.SET_TIME_WINDOW,
      MetricActionTypes.SET_ACTIVE_METRIC,
      MetricActionTypes.UNSET_ACTIVE_METRIC,
      MetricActionTypes.SET_SELECTED_METRIC,
      MetricActionTypes.BULK_SET_METRICS,
      MetricActionTypes.UPDATE_METRIC,
    ],
    saveDashboardSession,
  );
}

// Root Saga
export default function* rootSaga() {
  yield all([fork(userDashboardWatcher)]);
}
