import { UserToken } from '@/api/user/interface';

export interface SessionState {
  stage: InitializationStages;
  error?: string;
  message: string;
  token?: UserToken;
}

export enum InitializationStages {
  NONE = 'NONE',
  VALIDATE_AUTH_CODE = 'VALIDATE_AUTH_CODE',
  SELECT_PROFILE = 'SELECT_PROFILE',
  FETCH_INFO = 'FETCH_INFO',
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
}

export enum SessionActionTypes {
  VALIDATE_AUTH_CODE = '@@distilr/session/VALIDATE_AUTH_CODE',
  MUST_SELECT_PROFILE = '@@distilr/session/MUST_SELECT_PROFILE',
  FETCH_USER_DATA = '@@distilr/session/FETCH_USER_DATA',
  SET_SESSION_TOKEN = '@@distilr/session/SET_SESSION_TOKEN',
  SET_SESSION_INITIALIZED = '@@distilr/session/SET_SESSION_INITIALIZED',
  SET_SESSION_ERROR = '@@distilr/session/SET_SESSION_ERROR',
}

export interface ValidateAuthCodeAction {
  type: SessionActionTypes.VALIDATE_AUTH_CODE;
  payload: string;
}

export interface MustSelectProfileAction {
  type: SessionActionTypes.MUST_SELECT_PROFILE;
}

export interface FetchUserDataAction {
  type: SessionActionTypes.FETCH_USER_DATA;
}

export interface SetSessionTokenAction {
  type: SessionActionTypes.SET_SESSION_TOKEN;
  payload: UserToken;
}

export interface SetTokenErrorAction {
  type: SessionActionTypes.SET_SESSION_ERROR;
  payload: string;
}

export interface SetSessionInitializedAction {
  type: SessionActionTypes.SET_SESSION_INITIALIZED;
}

export type SessionActions =
  | ValidateAuthCodeAction
  | MustSelectProfileAction
  | FetchUserDataAction
  | SetSessionTokenAction
  | SetTokenErrorAction
  | SetSessionInitializedAction;
