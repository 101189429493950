import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '@/store/sagas';
import reducers, { ApplicationState } from '@/store/reducers';

const sagaMiddleware = createSagaMiddleware();

export function configureStore(initialState?: ApplicationState) {
  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}

const store = configureStore();
export default store;
