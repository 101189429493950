import { Reducer } from 'redux';
import { UserRoles } from '@/api/user/interface';
import { UserState, UserActions, UserActionTypes } from '@/store/user/types';
import { sendEvent } from '@/util/analytics';
import { GA_EVENTS } from '@/constants';

export const INITIAL_STATE: UserState = {
  categories: [],
  customers: [],
  insightPreferences: [],
  profiles: [],
  selectedProfile: undefined,
  role: UserRoles.None,
  username: '',
};

// Reducer
const reducer: Reducer<UserState, UserActions> = (state = INITIAL_STATE, action): UserState => {
  switch (action.type) {
    case UserActionTypes.SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case UserActionTypes.SET_USER_PROFILES:
      return {
        ...state,
        profiles: action.payload,
      };
    case UserActionTypes.SET_SELECTED_USER_PROFILE:
    case UserActionTypes.UPDATE_SELECTED_USER_PROFILE:
      sendEvent(GA_EVENTS.USER_SET_SELECTED_PROFILE);
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case UserActionTypes.SET_USER_PERMISSIONS:
      return {
        ...state,
        categories: action.payload.category_list.sort(sortPushNullToEnd),
        customers: action.payload.customers,
      };
    case UserActionTypes.SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case UserActionTypes.SET_USER_INSIGHT_PREFERENCES:
      return {
        ...state,
        insightPreferences: action.payload || [],
      };
    case UserActionTypes.ADD_USER_INSIGHT_PREFERENCE:
      return {
        ...state,
        insightPreferences: [...state.insightPreferences, action.payload],
      };
    default:
      return state;
  }
};

export default reducer;

function sortPushNullToEnd(a: string, b: string) {
  const aVal = a.toLowerCase();
  const bVal = b.toLowerCase();

  if (aVal === 'n/a') {
    return 1;
  }

  if (bVal === 'n/a') {
    return -1;
  }

  return aVal.localeCompare(bVal);
}
