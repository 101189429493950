import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InitializationStages } from '@/store/session/types';
import { sessionStageSelector } from '@/store/session/selectors';

const RestrictedRoute: FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  const stage = useSelector(sessionStageSelector);
  if (stage !== InitializationStages.INITIALIZED) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={Component} />;
};

export default RestrictedRoute;
