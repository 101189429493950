import { createSelector, Selector } from 'reselect';
import { ApplicationState } from '@/store/reducers';
import { SessionState } from '@/store/session/types';

const baseSelector: Selector<ApplicationState, SessionState> = (state: ApplicationState) =>
  state.session;

export const sessionStageSelector = createSelector(baseSelector, (session) => session.stage);
export const sessionErrorSelector = createSelector(baseSelector, (session) => session.error);
export const sessionMessageSelector = createSelector(baseSelector, (session) => session.message);
