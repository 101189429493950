import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { URLS } from '@/constants';
import analytics from '@/util/analytics';
import RestrictedRoute from '@/views/RestrictedRoute';
import Spinner from '@/components/Base/Spinner/Centered';

const Login = lazy(() => import('@/views/Login'));
const Dashboard = lazy(() => import('@/views/Dashboard'));
const OMS_URL = `${process.env.REACT_APP_OMS_URL}/authorize?client_id=${process.env.REACT_APP_OMS_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_PUBLIC_URL}/${URLS.LOGIN}`;

function Routes() {
  const { pathname, search } = useLocation();

  // Init Analytics
  useEffect(() => {
    analytics.init();
  }, []);

  // Send a page view event
  useEffect(() => {
    analytics.sendPageView(`${pathname}${search}`);
  }, [pathname, search]);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            window.location.assign(OMS_URL);
            return null;
          }}
        />
        <Route exact path={`/${URLS.LOGIN}`} component={Login} />
        <RestrictedRoute exact path={`/${URLS.DASHBOARD}`} component={Dashboard} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
