import api, { AxiosResponse } from '@/api';
import { DrilldownFilter } from '@/components/DrilldownPanel/contexts/useDrilldown';
import {
  CategoryItem,
  // DrilldownItem,
  MetricGraph,
  MetricSummary,
  PostMetricsDrilldownRequest,
  PostMetricsGraphRequest,
  PostMetricsSummaryRequest,
  GetMetricsDateResponse,
  PostFilterCategoryResponse,
  PostMetricGraphResponse,
  PostMetricSummaryResponse,
  PostMetricsDrilldownResponse,
  DrilldownData,
} from './interface';

export async function getLatestUpdatedDate(): Promise<string> {
  const response: AxiosResponse<GetMetricsDateResponse> = await api.get('/metrics/date');

  if (response.data['body-json'].errors?.length) {
    throw new Error(response.data['body-json'].errors);
  }

  return response.data['body-json'].body[0].refresh_date;
}

export async function getCategoryItems(category: string): Promise<CategoryItem[]> {
  const response: AxiosResponse<PostFilterCategoryResponse> = await api.post('/filter/category', {
    category_name: category,
  });

  // TODO: Fix API
  if (response.data.errors?.length) {
    throw new Error(response.data.errors);
  }

  return response.data.body;
}

export async function getMetricSummary(params: PostMetricsSummaryRequest): Promise<MetricSummary> {
  const requestData = mapRequest(params);
  const response: AxiosResponse<PostMetricSummaryResponse> = await api.post(
    '/metrics',
    requestData,
  );

  // TODO: Fix API
  if (response.data['body-json'].errors?.length) {
    throw new Error(response.data['body-json'].errors);
  }

  return response.data['body-json'].body;
}

export async function getMetricGraph(params: PostMetricsGraphRequest): Promise<MetricGraph> {
  const requestData = mapRequest(params);
  const response: AxiosResponse<PostMetricGraphResponse> = await api.post('/metrics', requestData);

  if (response.data['body-json'].errors?.length) {
    throw new Error(response.data['body-json'].errors);
  }

  return response.data['body-json'].body;
}

export async function getDrilldownTableData({
  metrics,
  level,
  filters,
  timeWindow,
  page,
  isDrilldown,
  order,
  orderBy,
}: PostMetricsDrilldownRequest): Promise<DrilldownData> {
  const dynamic_clause = createDrilldownDynamicClauseFromFilters(filters);

  const response: AxiosResponse<PostMetricsDrilldownResponse> = await api.post(
    '/metrics/drilldown',
    {
      id: metrics,
      time_window: timeWindow,
      dynamic_clause: Object.values(dynamic_clause),
      breakdown_by: level,
      page_no: page + 1,
      is_drilldown: isDrilldown,
      order: order,
      orderBy: orderBy,
    },
  );

  return response.data['body-json'].body;
}

// Utils
function mapRequest(params: PostMetricsSummaryRequest | PostMetricsGraphRequest) {
  const { filter, ...standardParams } = params;

  if (!filter) {
    return standardParams;
  }

  return {
    ...standardParams,
    dynamic_clause: [
      ...mapDynamicClause(filter.customer.filters),
      ...mapDynamicClause(filter.product.filters),
    ],
  };
}

function mapDynamicClause(filter: any) {
  return Object.entries(filter).map(([key, values]) => ({
    column_name: key,
    values,
  }));
}

function createDrilldownDynamicClauseFromFilters(filters: { [key: string]: DrilldownFilter[] }) {
  type ObjStructure = {
    [key: string]: {
      column_name: string;
      values: string[];
    };
  };

  return Object.values(filters).reduce((acc: ObjStructure, val) => {
    val.forEach((filter) => {
      if (acc[filter.level]) {
        acc[filter.level].values.push(filter.value);
        return;
      }
      acc[filter.level] = {
        column_name: filter.level,
        values: [filter.value],
      };
    });
    return acc;
  }, {});
}
