import { combineReducers } from 'redux';
import session from '@/store/session/reducer';
import { SessionState, SessionActions } from '@/store/session/types';
import metric from '@/store/metric/reducer';
import { MetricState, MetricActions } from '@/store/metric/types';
import filter from '@/store/filter/reducer';
import { FilterState, FilterActions } from '@/store/filter/types';
import user from '@/store/user/reducer';
import { UserState, UserActions } from '@/store/user/types';

const rootReducer = combineReducers({
  filter,
  metric,
  session,
  user,
});

export interface ApplicationState {
  filter: FilterState;
  metric: MetricState;
  session: SessionState;
  user: UserState;
}

export type ApplicationActions = SessionActions | MetricActions | FilterActions | UserActions;

export default rootReducer;
