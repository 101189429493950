import { Reducer } from 'redux';
import {
  SessionActions,
  SessionState,
  SessionActionTypes,
  InitializationStages,
} from '@/store/session/types';

export const INITIAL_STATE: SessionState = {
  error: undefined,
  message: 'Getting things started...',
  stage: InitializationStages.NONE,
  token: {
    accessToken: '',
    refreshToken: '',
    expires: 0,
    initialized: '',
  },
};

// Reducer
const reducer: Reducer<SessionState, SessionActions> = (
  state = INITIAL_STATE,
  action,
): SessionState => {
  switch (action.type) {
    case SessionActionTypes.VALIDATE_AUTH_CODE:
      return {
        ...state,
        message: 'Validating user...',
        stage: InitializationStages.VALIDATE_AUTH_CODE,
      };
    case SessionActionTypes.MUST_SELECT_PROFILE:
      return {
        ...state,
        stage: InitializationStages.SELECT_PROFILE,
      };
    case SessionActionTypes.FETCH_USER_DATA:
      return {
        ...state,
        stage: InitializationStages.FETCH_INFO,
        message: 'Loading your info...',
      };
    case SessionActionTypes.SET_SESSION_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SessionActionTypes.SET_SESSION_INITIALIZED:
      return {
        ...state,
        stage: InitializationStages.INITIALIZED,
      };
    case SessionActionTypes.SET_SESSION_ERROR:
      return {
        ...INITIAL_STATE,
        error: action.payload,
        stage: InitializationStages.ERROR,
      };
    default:
      return state;
  }
};

export default reducer;
