import { createSelector } from 'reselect';
import { ApplicationState } from '@/store/reducers';

import { MetricListGroupedType } from '@/store/metric/types';

const baseSelector = (state: ApplicationState) => state.metric;
export const metricIsLoadingSelector = createSelector(baseSelector, (metric) => metric.loading);
export const metricListSelector = createSelector(baseSelector, (metric) => metric.list);
export const metricDefaultVariantListSelector = createSelector(metricListSelector, (list) =>
  list.map((item) => ({
    ...item,
    ...item.variants.find((variant) => variant.is_metric_group_default)!,
  })),
);
export const metricSelectedSelector = createSelector(baseSelector, (metric) => metric.selected);
export const metricSelectedLengthSelector = createSelector(
  baseSelector,
  (metric) => metric.selected.length,
);

export const metricListByParentSelector = createSelector(metricListSelector, (list) =>
  Object.values(
    list.reduce((acc: { [key: string]: MetricListGroupedType }, metric) => {
      if (!acc[metric.parent]) {
        acc[metric.parent] = {
          id: metric.parent,
          name: metric.parent,
          items: [metric],
        };
      } else {
        acc[metric.parent].items.push(metric);
      }

      return acc;
    }, {}),
  ),
);

export const metricDetailsByVariantSelector = (id: string) =>
  createSelector(metricListSelector, (list) =>
    list.find((i) => i.variants.find((v) => v.metric_code === id)),
  );

export const referencePointDetailsSelector = (metricGroupId: string, referencePoint: string) =>
  createSelector(metricListSelector, (list) => {
    const metric = list.find((li) => li.metric_group_id === metricGroupId);
    return metric
      ? metric.reference_points.find((rp) => rp.reference_point === referencePoint)
      : undefined;
  });

export const metricActiveSelector = createSelector(baseSelector, (metric) =>
  metric.selected.find((metric) => metric.selected),
);
