import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Spinner from '@/components/Base/Spinner';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}));

const CenteredSpinner: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Spinner />
    </Grid>
  );
};

export default CenteredSpinner;
