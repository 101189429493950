import { UserFilterType } from '@/api/filter/interface';
import { UserSession } from '@/api/user/interface';

export interface FilterState {
  timeWindow: TimeWindowState;
  activeFilter: UserFilterType | null;
  filters: UserFilterType[] | [];
  latestUpdatedDate?: string;
}

// Object Interfaces
export interface TimeWindowState {
  preset: string;
  startDate?: string;
  endDate?: string;
}

// Actions
export enum FilterActionTypes {
  SET_TIME_WINDOW = '@@distilr/metric/SET_TIME_WINDOW',
  ADD_FILTER = '@@distilr/metric/ADD_FILTER',
  REMOVE_FILTER = '@@distilr/metric/REMOVE_FILTER',
  UPDATE_FILTER = '@@distilr/metric/UPDATE_FILTER',
  SET_ACTIVE_FILTER = '@@distilr/metric/SET_ACTIVE_FILTER',
  SET_FILTERS = '@@distilr/metric/SET_FILTERS',
  SET_PREVIOUS_SESSION = '@@distilr/metric/SET_PREVIOUS_SESSION',
  SET_LATEST_UPDATED_DATE = '@@distilr/data/SET_LATEST_UPDATED_DATE',
}

export interface SetLatestUpdatedDateType {
  type: FilterActionTypes.SET_LATEST_UPDATED_DATE;
  payload: string;
}

export interface SetTimeWindowType {
  type: FilterActionTypes.SET_TIME_WINDOW;
  payload: TimeWindowState;
}

export interface AddFilterType {
  type: FilterActionTypes.ADD_FILTER;
  payload: UserFilterType;
}

export interface RemoveFilterType {
  type: FilterActionTypes.REMOVE_FILTER;
  payload: UserFilterType;
}

export interface UpdateFilterType {
  type: FilterActionTypes.UPDATE_FILTER;
  payload: UserFilterType;
}

export interface SetFilterType {
  type: FilterActionTypes.SET_FILTERS;
  payload: UserFilterType[];
}

export interface SetActiveFilterType {
  type: FilterActionTypes.SET_ACTIVE_FILTER;
  payload: UserFilterType | null;
}

export interface SetPreviousSessionType {
  type: FilterActionTypes.SET_PREVIOUS_SESSION;
  payload: UserSession;
}

export type FilterActions =
  | SetTimeWindowType
  | AddFilterType
  | RemoveFilterType
  | UpdateFilterType
  | SetFilterType
  | SetActiveFilterType
  | SetPreviousSessionType
  | SetLatestUpdatedDateType;
