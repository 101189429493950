import { BrowserRouter as Router } from 'react-router-dom';
import Provider from '@/components/Provider';
import { ToastContainer } from 'react-toastify';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/styles.css';

import Routes from '@/views/Routes';

function App() {
  require('@/assets/scripts/pendo.js')({ PENDO_KEY: '83f5e95e-ec44-4dc3-7b8d-9991238ffd6e' });
  return (
    <Provider>
      <Router>
        <Routes />
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
