import { UserToken } from '@/api/user/interface';
import { SessionActionTypes, SessionActions } from '@/store/session/types';

export function validateAuthCode(code: string): SessionActions {
  return {
    type: SessionActionTypes.VALIDATE_AUTH_CODE,
    payload: code,
  };
}

export function setMustSelectProfile(): SessionActions {
  return {
    type: SessionActionTypes.MUST_SELECT_PROFILE,
  };
}

export function setFetchUserData(): SessionActions {
  return {
    type: SessionActionTypes.FETCH_USER_DATA,
  };
}

export function setSessionToken(token: UserToken): SessionActions {
  return {
    type: SessionActionTypes.SET_SESSION_TOKEN,
    payload: token,
  };
}

export function setSessionError(error: string): SessionActions {
  return {
    type: SessionActionTypes.SET_SESSION_ERROR,
    payload: error,
  };
}

export function setSessionInitialized(): SessionActions {
  return {
    type: SessionActionTypes.SET_SESSION_INITIALIZED,
  };
}
