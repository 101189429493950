import { ActiveMetricType, MetricItem, MetricReferencePoint } from '@/api/user/interface';
import { MetricActionTypes, MetricActions } from '@/store/metric/types';

export function addReferencePoint(newReferencePoint: MetricReferencePoint) {
  return {
    type: MetricActionTypes.ADD_REFERENCE_POINT,
    payload: newReferencePoint,
  };
}

export function removeReferencePoint(referencePointIds: string[]) {
  return {
    type: MetricActionTypes.REMOVE_REFERENCE_POINT,
    payload: referencePointIds,
  };
}

export function setMetricList(list: MetricItem[]): MetricActions {
  return {
    type: MetricActionTypes.SET_METRIC_LIST,
    payload: list,
  };
}

export function setActiveMetric(item: ActiveMetricType): MetricActions {
  return {
    type: MetricActionTypes.SET_ACTIVE_METRIC,
    payload: item,
  };
}

export function unsetActiveMetric(id: string): MetricActions {
  return {
    type: MetricActionTypes.UNSET_ACTIVE_METRIC,
    payload: id,
  };
}

export function setSelectedMetric(id: string): MetricActions {
  return {
    type: MetricActionTypes.SET_SELECTED_METRIC,
    payload: id,
  };
}

export function bulkSetSelectedMetric(metrics: ActiveMetricType[]): MetricActions {
  return {
    type: MetricActionTypes.BULK_SET_METRICS,
    payload: metrics,
  };
}

export function updateMetric(
  id: string,
  updates: { [key: string]: string | boolean },
): MetricActions {
  return {
    type: MetricActionTypes.UPDATE_METRIC,
    payload: {
      id,
      updates,
    },
  };
}
