import { UserFilterType } from '@/api/filter/interface';

export enum UserRoles {
  AcctMgr = 'AcctMgr',
  GPOUser = 'GPOUser',
  None = 'None',
  User = 'User',
}

export interface GetAuthResponse {
  access_token: string;
  expires_in: string;
  refresh_token: string;
  role: UserRoles;
  profiles: UserProfile[];
}

export interface GetMetricsResponse {
  ['body-json']: {
    errors?: string;
    statusCode: number;
    metrics_list: MetricItem[];
  };
}

export interface GetFilterResponse {
  errors?: string;
  statusCode: number;
  body: UserPermissions;
}

export interface GetUserMetricsResponse {
  session: UserSession;
}

export interface GetUserResponse {
  count: number;
  filters: UserFilterType[];
  user_name: string;
}

export interface UserProfile {
  team_name: string;
  team_description: string;
}

export interface UserInfo {
  filters: UserFilterType[];
  user_name: string;
}

export interface UserProfileForPendo {
  role: UserRoles;
  username: string;
}

export interface AuthDetails {
  token: UserToken;
  role: UserRoles;
  profiles: UserProfile[];
}

export interface UserToken {
  accessToken: string;
  refreshToken: string;
  expires: number;
  initialized: string;
}

export interface MetricItem {
  metric_family_id: string;
  metric_group_id: string;
  metric_group_display_name: string;
  metric_family: string;
  metric_group: string;
  parent: string;
  reference_points: MetricReferencePoint[];
  unit_change_type: string;
  unit_change_type_prefix: string;
  unit_change_type_suffix: string;
  unit_type: string;
  unit_type_prefix: string;
  unit_type_suffix: string;
  variants: MetricVariant[];
}

export interface MetricVariant {
  metric_group: string;
  display_name: string;
  hierarchy?: number;
  metric_code: string;
  metric_calc?: string;
  variant_code: string;
  variant_display_name: string;
  variant_id: string;
  variant_family_display_name: string;
  is_metric_group_default: boolean;
}

export interface MetricReferencePoint {
  display_name: string;
  reference_point: string;
  reference_point_type: string;
  reference_point_id: string;
}

export interface MemberOrganizationType {
  customer_id: string;
  region: string;
  division: string;
  sector: string;
  province_state: string;
  city: string;
  distribution_center: string;
}

export interface UserMemberCustomerType {
  city: string;
  customercodekey: number;
  customername: string;
  distributorclassification: string;
  distributorkey: number;
  distributorname: string;
  district: string;
  division: string;
  region: string;
  sector: string;
  state_prov: string;
  teams_customercode: string;
  teams_districtcode: string;
  teams_divisioncode: string;
  teams_regioncode: string;
  teams_sectorcode: string;
}

export interface UserPermissions {
  category_list: string[];
  customers: UserMemberCustomerType[];
}

export interface UserSession {
  window_size: string;
  filter_name: string;
  metrics: ActiveMetricType[];
}

export interface ActiveMetricType {
  metric_group_id: string;
  reference_point: string;
  metric_code: string;
  variant_display_name: string;
  selected: boolean;
}
