import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import store from '@/store';
import { getNewTokenFromRefreshToken, isUserTokenValid } from '@/api/user';
import { GetAuthResponse, UserToken, UserProfile } from '@/api/user/interface';
import { setSessionToken } from '@/store/session/actions';

// Create an instance of axios for every call to use
const api = axios.create({
  baseURL: process.env.REACT_APP_DISTILR_API,
});

// Pass token to every non token request
api.interceptors.request.use(async (request: AxiosRequestConfig) => {
  // This is an initial auth code request, no need to pass the token
  if (request.url?.includes('/auth') && request.params.code) {
    return request;
  }

  const prevToken = fetchTokenFromState();

  if (isUserTokenValid(prevToken)) {
    request.headers['auth-header'] = prevToken!.accessToken;
    return request;
  }

  const token: UserToken = await getNewTokenFromRefreshToken(prevToken);
  saveTokenToState(token);
  return request;
});

// Pass profile to certain requests
api.interceptors.request.use(async (request: AxiosRequestConfig) => {
  const ENDPOINTS = [
    { method: 'get', url: '/filter' },
    { method: 'post', url: '/filter/category' },
    { method: 'post', url: '/filter/industry' },
    { method: 'post', url: '/metrics' },
    { method: 'post', url: '/metrics/drilldown' },
    { method: 'get', url: '/insights/group' },
    { method: 'get', url: '/user' },
    { method: 'post', url: '/user' },
  ];

  const isProfileNeeded = ENDPOINTS.some(
    (endpoint) => request.method === endpoint.method && request.url === endpoint.url,
  );
  if (isProfileNeeded) {
    const profile = fetchProfileFromState();
    request.headers['profile'] = profile?.team_name;
    return request;
  }

  return request;
});

api.interceptors.response.use((response: AxiosResponse) => {
  // If we get a token back save to state
  // TODO: Security
  if (response.config.url?.includes('/auth')) {
    const data = (response as AxiosResponse<GetAuthResponse>).data;
    const token: UserToken = {
      accessToken: data.access_token,
      expires: parseInt(data.expires_in, 10),
      initialized: DateTime.local().toISO(),
      refreshToken: data.refresh_token,
    };
    saveTokenToState(token);
  }

  return response;
});

export function saveTokenToState(token: UserToken): void {
  store.dispatch(setSessionToken(token));
}

function fetchTokenFromState(): UserToken | undefined {
  const state = store.getState();
  return state.session.token;
}

function fetchProfileFromState(): UserProfile | undefined {
  const state = store.getState();
  return state.user.selectedProfile;
}

export type { AxiosResponse };
export default api;
