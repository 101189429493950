import { UserActions, UserActionTypes } from '@/store/user/types';
import { UserPermissions, UserProfile, UserRoles } from '@/api/user/interface';
import { UserInsightPreference } from '@/api/insights/interface';

export function setUserRole(role: UserRoles): UserActions {
  return {
    type: UserActionTypes.SET_USER_ROLE,
    payload: role,
  };
}

export function setUserProfiles(profiles: UserProfile[]): UserActions {
  return {
    type: UserActionTypes.SET_USER_PROFILES,
    payload: profiles,
  };
}

export function setSelectedUserProfile(profile: UserProfile): UserActions {
  return {
    type: UserActionTypes.SET_SELECTED_USER_PROFILE,
    payload: profile,
  };
}

export function updateSelectedUserProfile(profile: UserProfile): UserActions {
  return {
    type: UserActionTypes.UPDATE_SELECTED_USER_PROFILE,
    payload: profile,
  };
}

export function setUserPermissions(permissions: UserPermissions): UserActions {
  return {
    type: UserActionTypes.SET_USER_PERMISSIONS,
    payload: permissions,
  };
}

export function setUserName(username: string): UserActions {
  return {
    type: UserActionTypes.SET_USERNAME,
    payload: username,
  };
}

export function setUserInsightPreferences(preferences: UserInsightPreference[]): UserActions {
  return {
    type: UserActionTypes.SET_USER_INSIGHT_PREFERENCES,
    payload: preferences,
  };
}

export function addUserInsightPreference(preference: UserInsightPreference): UserActions {
  return {
    type: UserActionTypes.ADD_USER_INSIGHT_PREFERENCE,
    payload: preference,
  };
}
